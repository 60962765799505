<template>
   <div class="cardServers cursor-pointer mb-0" @click="selectServer">
      <div class="card-body text-center">
         <div class="circle">
            <img class="rounded-circle bordaIco" v-if="server.url_icon == null" :src="'https://cdn.discordapp.com/icons/'+ server.id +'/'+ server.icon +'.png'" @error="imageError">
            <img class="rounded-circle bordaIco" v-else :src="server.url_icon" @error="imageError">
         </div>
         <h6 class="limitador mt-3 mb-0">{{ server.name }}</h6>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Server',
   props: ['server'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      selectServer : function () {
         this.$emit('selectServer', this.server)
      }
   }
}

</script>

<style scoped>

img {
   width: 110px;
   height: 110px;
}

.card:hover {
   transform: translateY(-6px);
}

</style>