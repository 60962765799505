<template>
	<div class="row">
		<div class="col-12 mb-2">
			<div class="card mb-1">
				<div class="card-body">
					<label class="font-15 mb-2"><i class="far fa-search color-theme font-13 mr-1"></i> {{ $t('paginaInicial.labelSearch') }}</label>
					<input type="text" class="form-control rounded" :placeholder="$t('paginaInicial.placeholderSearch')" v-model="inputPesquisa" v-focus>
				</div>
			</div>
		</div>

		<div class="col-12 mb-2" v-if="servidores.admin != null && servidores.admin.length > 0">
			<div class="card form-group c_form_group m-0 p-3 rounded">
				<h6 class="mb-3"><i class="far fa-long-arrow-alt-right color-theme font-13 mr-1"></i> Admin</h6>
				<div class="cards-2">
					<server v-for="(server, index) in servidores.admin" :key="index" :server="server" :index="index" @selectServer="selectServer($event)" />
				</div>
			</div>
		</div>

		<div class="col-12 mb-5" v-if="servidores.others != null && servidores.others.length > 0">
			<div class="card form-group c_form_group m-0 p-3 rounded">
				<h6 class="mb-3"><i class="far fa-long-arrow-alt-right color-theme font-13 mr-1"></i>{{ $t('paginaInicial.labelGuilds') }}</h6>
				<div class="cards-2">
					<server v-for="(server, index) in servidores.others" :key="index" :server="server" :index="index" @selectServer="selectServer($event)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import server from '@/components/Server.vue'

export default {
	name: 'My_Servers',
	data: function () {
		return {
			inputPesquisa: ""
		}
	},
	computed: {
		...mapState({
			tokenSessao: state => state.tokenSessao,
			dadosUsuario: state => state.dadosUsuario,
			servidores: state => state.servidores,
			serverS: state => state.serverS,
			urlRest: state => state.urlRest
		})
	},
	watch: {
		inputPesquisa: function (val) {
			var value = val.toLowerCase()

			$(".cards-2").filter(function () {
				$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
			})
		}
	},
	components: {
		server
	},
	methods: {
		selectServer : function(s) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest + 'servicos/verificarServidor',
				params: {
					idServidor: s.id,
					name: s.name,
					idUser: this.dadosUsuario.id
				}
			}).then((response) => {
				if (response.data.id != null) {
					this.$store.dispatch('updateServerS', response.data)
					router.push('/Dashboard')

				} else {
					router.push({ name: 'Add_deck_to_server', params: { server: s } })
				}
			}).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: this.$t("navBar.withoutPermission")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		this.$axios.interceptors.request.use((config) => {
			if (this.tokenSessao != null && this.dadosUsuario != null) {
				config.headers.Authorization = 'Bearer '+ this.tokenSessao +'@@@'+ this.dadosUsuario.email;
			}

			return config;
		});
	},
}

</script>

<style scoped>

.c_form_group .form-control {
	border: 1px solid var(--border-color);
	padding: .375rem .75rem;
}

</style>